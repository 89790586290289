import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { Constants } from '@auth-library/core/data/constants';
import { of, switchMap } from 'rxjs';

export const ResetPasswordGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    return of(route.queryParams).pipe(
        switchMap((queryParams) =>
        {
            // if there is no reset password key
            if ( !queryParams['key'] )
            {
                // Redirect to the sign-in page with a redirectUrl param
                const redirectURL = Constants.LOGIN_ROUTE;
                return of(router.createUrlTree([redirectURL]));
            }
            // Allow the access
            return of(true);
        }),
    );
};
