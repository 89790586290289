import { EnvironmentProviders, Provider } from "@angular/core"
import { LuxonDateAdapter } from "@angular/material-luxon-adapter"
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core"


export const provideDateAdapter = (): Array<Provider | EnvironmentProviders> => {
return[
    {
        provide : DateAdapter,
        useClass: LuxonDateAdapter,
    },
    {
        provide : MAT_DATE_FORMATS,
        useValue: {
            parse  : {
                dateInput: 'D',
            },
            display: {
                dateInput         : 'DDD',
                monthYearLabel    : 'LLL yyyy',
                dateA11yLabel     : 'DD',
                monthYearA11yLabel: 'LLLL yyyy',
            },
        },
    },
]
}
