import { AuthConfig, } from '@main-auth-config';
import { createProviders } from '@auth-library/config';
import { createRoutes } from '@auth-library/config';
import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { UniverjobHomeComponent } from './univerjob-home/univerjob-home.component';
import { appEnv } from '../environments/univerjob';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';


export const appConfig: ApplicationConfig = {
  providers: [
      provideRouter(createRoutes(UniverjobHomeComponent)),
      { provide: RECAPTCHA_V3_SITE_KEY, useValue: appEnv.ENDPOINTS.RECAPTCHA_KEY },
      ...createProviders(),
      {
        provide: AuthConfig,
        useFactory: () =>
          new AuthConfig({
            title: 'UniverJob',
            appName: 'UJ',
            poweredBy: 'TI',
            copyRightUrlRedirection: '#',
            portalSiteUrl: appEnv.ENDPOINTS.SITE_URL,
            customFeatures: null,
          },),
      },
  ],
};
