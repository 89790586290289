import { APP_INITIALIZER, EnvironmentProviders, Provider, inject } from "@angular/core";
import { provideFuse } from "@auth-library/@fuse";
import { provideIcons } from "@auth-library/core/icons/icons.provider";
import { provideHttpInterceptor } from "@auth-library/core/interceptors/http.provider";
import { provideDateAdapter } from "@auth-library/core/providers/date.provider";
import { translocoCustomProvider } from "@auth-library/core/transloco/transloco.provider";
import { mockApiServices } from "@auth-library/mock-api";
import { TranslocoService } from "@jsverse/transloco";
import { environment } from "environments/environment";
import { firstValueFrom } from "rxjs";
import { provideAnimations } from '@angular/platform-browser/animations';

export const createProviders = () => [
  provideAnimations(),
  provideHttpInterceptor(),
  // Material Date Adapter
  provideDateAdapter(),
  // Transloco Config
  translocoCustomProvider(),
  {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide   : APP_INITIALIZER,
      useFactory: () =>
      {
          const translocoService = inject(TranslocoService);
          const defaultLang = translocoService.getDefaultLang();
          translocoService.setActiveLang(defaultLang);

          return () => firstValueFrom(translocoService.load(defaultLang));
      },
      multi     : true,
  },
  provideIcons(),
  provideFuse({
      mockApi: {
          delay   : 0,
          services: mockApiServices,
      },
      fuse   : {
          layout : 'classy',
          scheme : 'light',
          screens: {
              sm: '600px',
              md: '960px',
              lg: '1280px',
              xl: '1440px',
          },
          theme  : 'theme-default',
          themes : [
              {
                  id  : 'theme-default',
                  name: 'Default',
              }
          ],
      },
  }),
];
